// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";
import CryptoJS from "crypto-js";

//store wishlist in local storage
function setWishes(data) {
    if (localStorage.getItem("ultramall-wishList")) {
        localStorage.removeItem("ultramall-wishList");
    }
    let Encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET).toString();
    localStorage.setItem("ultramall-wishList", Encrypted);
}

const initialState = {
    list: []
};

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState,
    reducers: {
        addToWishlist(state, action) {
            state.list.push(action.payload);
            setWishes(state.list);
        },
        reduceWishlist(state, action) {
            state.list = state.list.filter(item => item.id !== action.payload);
            setWishes(state.list);
        },
        setWishlist(state, action) {
            state.list = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { addToWishlist, reduceWishlist, setWishlist } = wishlistSlice.actions;

export default wishlistSlice.reducer;