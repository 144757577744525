import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Newsletter from "../components/Newsletter";
import Product from "../components/Product";
import { popularProducts } from "../data";
import { categories } from "../data";
import { mobile } from "../responsive";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const Products = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  ${mobile({ padding: "10px", flexDirection: "column" })}
`;


const CategoryProducts = () => {
  const param = useParams();
  const id = param.id;
  const Category = categories.find((item) => item.id === Number(id));
  let temp = [];

  popularProducts.map((item) => {
    for (let i = 0; i < item.category.length; i++) {
      if (item.category[i] === Category.slug) {
        temp.push(item);
      }
    }
    return item;
  });

  const wishlist = useSelector((state) => state.wishlist.list);

  let categoryProd = temp.map((item) => {
    if (wishlist.find((wish) => wish.id === item.id)) {
      return { ...item, iswishList: true };
    }
    return item;
  });

  return (
    <Container>
      <Title>{Category.title}</Title>
      <Products>
        {categoryProd.map((item) => {
          return <Product item={item} key={item.id} />;
        })}
      </Products>
      <Newsletter />
    </Container>
  );
};

export default CategoryProducts;
