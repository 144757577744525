import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { Search } from "@mui/icons-material";
import { mobile, tab } from "../responsive";
import SkeletonProduct from "../skeleton/SkeletonProduct";
import Newsletter from "../components/Newsletter";

const Wrapper = styled.div`
    width: 100%
    height: 200px;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Container = styled.div`
  height: 30px;
  width: 40%;
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
  ${tab({ width: "60%" })};
  ${mobile({ width: "90%" })};
`;

const Input = styled.input`
  width: 98%;
  height: 90%;
  border: none;
  ${mobile({ width: "95%" })};
`;

const SkeletonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SkeletonUnit = styled.div`
  flex: 25%;
  ${tab({ flex: "50%" })};
  ${mobile({ flex: "100%" })};
`;

const SearchPage = () => {
  const [products, setProducts] = useState(null);
  const skeletonCount = [1, 2, 3, 4, 5, 6, 7, 8];

  return (
    <>
      <Wrapper>
        <Container>
          <Input placeholder="Search Here..." autoFocus />
          <Search style={{ color: "gray", fontSize: 20 }} />
        </Container>
      </Wrapper>
      <SkeletonDiv>
        {!products &&
          skeletonCount.map((n) => {
            return (
              <SkeletonUnit key={n}>
                <SkeletonProduct />;
              </SkeletonUnit>
            );
          })}
      </SkeletonDiv>
      <Newsletter />
    </>
  );
};

export default SearchPage;
