import React from 'react';
import { Detector } from 'react-detect-offline';
import { FaWifi } from 'react-icons/fa';
import styled from 'styled-components';


const Container = styled.div`padding-top: 10px; text-align: center;  `;
const Heading = styled.h1`margin-bottom: 5px`;
const Message = styled.h4`margin: 0`;

const CheckConnection = (props) => {
    return (
        <>
            <Detector
                render={({ online }) => (
                    online ? props.children :
                        <Container>
                            <FaWifi style={{ color: "red", fontSize: "50px" }} />
                            <Heading>No Connection</Heading>
                            <Message>Please check your internet connection...</Message>
                        </Container>
                )}
            />
        </>
    )
}

export default CheckConnection;