import React from "react";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import {
  Favorite,
  FavoriteBorderOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import { styled } from "styled-components";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set } from "../features/cartCounter/cartCounterSlice";
import { addToWishlist, reduceWishlist } from "../features/wishlist/wishlistSlice";

const Info = styled.div`
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease;
  cursor: pointer;
`;

const Container = styled.div`
  flex: 1;
  margin: 5px;
  min-width: 280px;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5fbfd;
  position: relative;

  &:hover ${Info} {
    opacity: 1;
  }
`;

const Circle = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
`;

const Image = styled.img`
  height: 75%;
  z-index: 2;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  transition: all 0.5s ease;
  z-index: 3;

  &:hover {
    background-color: #e9f5f5;
    transform: scale(1.1);
  }
`;

const Product = ({ item }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Add or Remove from Wishlist
  const handleClick = ({ item }) => {
    if (item.iswishList === false) {
      dispatch(addToWishlist(item));
    } else {
      dispatch(reduceWishlist(item.id));
    };
  };

  // Add to Cart functions
  const SetCart = (data) => {
    let tempCart = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET).toString();
    localStorage.setItem("ultramall-cart", tempCart);
    dispatch(set(data.length));
  }

  const GetCart = () => {
    let tempCart = localStorage.getItem("ultramall-cart");
    if (tempCart) {
      let Decrypted = CryptoJS.AES.decrypt(tempCart, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
      return JSON.parse(Decrypted);
    }
    return [];
  }

  const addToCart = () => {
    if (!localStorage.getItem("ultramall-cart")) {
      let cart = [];
      cart.push(item);
      SetCart(cart);
    } else {
      let storedCart = GetCart();
      let inCart = false;
      for (var i = 0; i < storedCart.length; i++) {
        if (storedCart[i].id === item.id) {
          inCart = true;
          break;
        }
      }
      if (inCart === false) {
        storedCart.push(item);
        localStorage.removeItem("ultramall-cart");
        SetCart(storedCart);
      }
    }

    Swal.fire({
      title: "Added to Cart!!!",
      text: "Your product has been added to Cart",
      icon: "success",
      confirmButtonColor: "teal",
      confirmButtonText: "Go to Cart",
      showDenyButton: true,
      denyButtonColor: "black",
      denyButtonText: "Continue Shopping",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/cart");
      }
    });
  };

  return (
    <Container>
      <Circle />
      <Image src={item.img} />
      <Info>
        <Icon>
          <ShoppingCartOutlined onClick={() => addToCart()} />
        </Icon>
        <Icon>
          <NavLink to={"/product/" + item.id} className="link">
            <SearchOutlined />
          </NavLink>
        </Icon>
        <Icon onClick={() => handleClick({ item })}>
          {!item.iswishList ? (
            <FavoriteBorderOutlined />
          ) : (
            <Favorite style={{ color: "red" }} />
          )}
        </Icon>
      </Info>
    </Container>
  );
};

export default Product;
