import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  width: 100vw;
  height: auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #eee;
`;

const Wrapper = styled.div`
  width: 30%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "80%" })};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Input = styled.input`
  flex: 1;
  width: 300px;
  min-width: 60%;
  margin: 10px 0;
  padding: 10px;
`;

const Button = styled.button`
  width: 40%;
  font-size: 14px;
  border: none;
  padding: 15px 10px;
  background-color: teal;
  color: white;
  margin-bottom: 10px;
  cursor: pointer;
`;

const Link = styled.a`
  color: teal;
  margin: 5px 0;
  font-size: 12;
  text-decoration: underline;
  cursor: pointer;
`;

const Login = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Title>SIGN IN</Title>
          <Form>
            <Input placeholder="username"></Input>
            <Input placeholder="password"></Input>
            <Button>LOGIN</Button>
            <Link>Forgot Password?</Link>
            <Link>Create a New Account</Link>
          </Form>
        </Wrapper>
      </Container>
    </>
  );
};

export default Login;
