// DUCKS pattern
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: 0
};

const cartCounterSlice = createSlice({
    name: "cartCounter",
    initialState,
    reducers: {
        increment(state) {
            state.value++
        },
        decrement(state) {
            state.value--
        },
        incrementByAmount(state, action) {
            state.value += action.payload
        },
        reset(state) {
            state.value = 0
        },
        set(state, action) {
            state.value = action.payload
        }
    }
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount, reset, set } = cartCounterSlice.actions;

export default cartCounterSlice.reducer;