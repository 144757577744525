import React from "react";
import Categories from "../components/Categories";
import Newsletter from "../components/Newsletter";
import styled from "styled-components";

const Title = styled.h1`
  margin: 20px;
  text-align: left;
`

const Category = () => {
  return (
    <>
      <Title>Categories</Title>
      <Categories />
      <Newsletter />
    </>
  );
};

export default Category;
