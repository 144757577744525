import React from "react";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  width: 100vw;
  height: auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #eee;
`;

const Wrapper = styled.div`
  width: 40%;
  padding: 20px;
  background-color: white;
  ${mobile({ width: "80%" })};
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 300;
`;

const Input = styled.input`
  flex: 1;
  min-width: 40%;
  margin: 20px 10px 0 0;
  padding: 10px;
`;

const Agreement = styled.p`
  font-size: 14px;
  margin: 20px 0;
`;

const Button = styled.button`
  width: 40%;
  font-size: 14px;
  border: none;
  padding: 15px 20px;
  background-color: teal;
  color: white;
  cursor: pointer;
`;

const SignIn = styled.p`
  margin-top: 20px;
`;
const Link = styled.a`
  color: teal;
  font-weight: bold;
`;

const Register = () => {
  return (
    <>
      <Container>
        <Wrapper>
          <Title>CREATE AN ACCOUNT</Title>
          <Form>
            <Input placeholder="name"></Input>
            <Input placeholder="last name"></Input>
            <Input placeholder="username"></Input>
            <Input placeholder="email"></Input>
            <Input placeholder="password"></Input>
            <Input placeholder="confirm password"></Input>
            <Agreement>
              By creating an account, I consent to the processing of my personal
              data in accordance with the <Link href="#">PRIVACY POLICY</Link>
            </Agreement>
            <Button>SIGN UP</Button>
          </Form>
          <SignIn>
            Already have an Account? <Link href="#">Sign In</Link>
          </SignIn>
        </Wrapper>
      </Container>
    </>
  );
};

export default Register;
