import React from "react";
import CryptoJS from "crypto-js";
import styled from "styled-components";
import { Add, Remove } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { set } from "../features/cartCounter/cartCounterSlice";
import { mobile, tab } from "../responsive";
import Newsletter from "../components/Newsletter";

const Container = styled.div``;

const Wrapper = styled.div`
  padding: 20px;
  max-width: 100%;
  margin: auto;
  ${tab({ padding: "10px" })};
`;

const Title = styled.h1`
  font-weight: 300;
  text-align: center;
`;

const EmptyCart = styled.div`
  display: flex;
  flex-direction: column;
  height: 40vh;
  align-items: center;
  justify-content: center;
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  max-width: 100%;
`;

const TopButton = styled.button`
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  border: ${(props) => (props.type === "filled" ? "none" : "1px solid teal")};
  background-color: ${(props) =>
    props.type === "filled" ? "teal" : "transparent"};
  color: ${(props) => (props.type === "filled" ? "white" : "teal")};
`;

const MobileTopTexts = styled.div`
  padding: 0;
  height: 1px;
  display: flex;
  justify-content: center;
  visibility: hidden;
  ${mobile({ visibility: "visible", height: "auto", padding: "10px 0" })};
`;

const TopTexts = styled.div`
  ${mobile({ display: "none" })};
`;

const TopText = styled.span`
  text-decoration: underline;
  cursor: pointer;
  margin: 0 10px;
`;

const Bottom = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mobile({ flexDirection: "column" })}
`;

const Info = styled.div`
  flex: 3;
  max-width: 100%;
  margin-bottom: 20px;
  ${tab({ flex: 2.7 })}
`;

const Product = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #eee;
  ${mobile({ flexDirection: "column", marginBottom: "10px" })};
`;

const ProductDetail = styled.div`
  flex: 2.3;
  display: flex;
`;

const Image = styled.img`
  width: 200px;
  max-width: 100%;
  height: auto;
`;

const Details = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ProductName = styled.span`
  margin-bottom: 20px;
`;

const ProductId = styled.span`
  margin-bottom: 20px;
`;

const ProductColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-bottom: 20px;
`;

const ProductSize = styled.span``;

const PriceDetail = styled.div`
  flex: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({ marginTop: "20px" })}
`;

const Bold = styled.b`
  color: teal;
`;

const ProductQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Quantity = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const ProductPrice = styled.div`
  font-size: 30px;
  font-weight: 200;
`;

const RemoveItem = styled.button`
  width: 80px;
  border: none;
  padding: 10px;
  margin-top: 20px;
  background-color: #000;
  color: white;
  cursor: pointer;
  font-weight: 600;

  &:hover {
    text-transform: uppercase;
    background-color: red;
    color: white;
    transition: all 0.4s ease;
  }

  ${mobile({ width: "100%" })};
`;

const Summary = styled.div`
  flex: 1;
  height: 60vh;
  max-width: 100%;
  border: 0.5px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  margin: 0 auto;
  ${mobile({ width: "95%" })};
  ${tab({ height: "100%", flex: 1.3 })};
  `;

const SummaryTitle = styled.h1`
  font-weight: 400;
  font-size: 2.7vw;
  text-align: center;
  ${mobile({ fontSize: "9.5vw" })}
  ${tab({ fontSize: "3.5vw" })}
`;

const SummaryItem = styled.div`
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
  font-weight: ${(props) => props.type === "total" && "500"};
  font-size: ${(props) => props.type === "total" && "24px"};
`;

const SummaryItemText = styled.span``;

const SummaryItemPrice = styled.span``;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  font-size: 24px;
  color: white;
  background-color: teal;
  border: none;
  cursor: pointer;
`;

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formatter = new Intl.NumberFormat("en");
  let loaded = false;

  const [cart, setCart] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [total, setTotal] = useState(0);


  let copyCart = [...cart];
  const [reverseCart, setReverseCart] = useState(copyCart.reverse());

  const SetCart = (data) => {
    let tempCart = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET).toString();
    localStorage.setItem("ultramall-cart", tempCart);
    dispatch(set(data.length));
  }

  const GetCart = () => {
    let tempCart = localStorage.getItem("ultramall-cart");
    if (tempCart) {
      let Decrypted = CryptoJS.AES.decrypt(tempCart, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
      return JSON.parse(Decrypted);
    }
    return [];
  }

  const storedCart = GetCart();

  const checkCart = async () => {
    if (storedCart) {
      await setCart(GetCart());
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    checkCart();
    loaded = true;
  }, []);

  useEffect(() => {
    orderSummary();
    copyCart = [...cart];
    setReverseCart(copyCart.reverse());
  }, [cart]);

  const orderSummary = () => {
    if (cart.length > 0) {
      // Calculate and Set Subtotal
      const newSubtotal = cart.reduce((acc, item) => {
        return (acc += item.price * item.quantity);
      }, 0);
      setSubtotal(newSubtotal);

      // Calculate and Set Shipping
      const newShipping = 0.1 * newSubtotal;
      setShipping(newShipping);

      // Calculate and Set Discount
      let newDiscount;
      if (newSubtotal > 99999.99) {
        newDiscount = newShipping / 2;
      } else {
        newDiscount = 0;
      }
      setDiscount(newDiscount);

      // Calculate and Set Total
      const newTotal = newSubtotal + newShipping - newDiscount;
      setTotal(newTotal);
    }
  };

  const handleClick = (option, { item }) => {
    var newQuantity = 0;
    let tempCart = [];

    if (option === "add") {
      newQuantity = Number(item.quantity) + 1;
    } else if (option === "minus") {
      newQuantity = Number(item.quantity) - 1;
    }

    if (newQuantity === 0) {
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].id !== item.id) {
          tempCart.push(cart[i]);
        }
      }
    } else {
      tempCart = cart.map((product) => {
        if (item.id === product.id) {
          product.quantity = newQuantity;
        }
        return product;
      });
    }

    setCart(tempCart);
    storedCart && localStorage.removeItem("ultramall-cart");
    SetCart(tempCart);
  };

  return (
    <Container>
      <Wrapper>
        <Title>CART</Title>
        {cart.length < 1 && (
          <EmptyCart>
            <h1>Your Cart is Empty!!!</h1> <br />
            <TopButton type="filled" onClick={() => navigate("/productList")}>
              SHOP NOW
            </TopButton>
          </EmptyCart>
        )}

        {cart.length > 0 && (
          <>
            <MobileTopTexts>
              <>
                <TopText>
                  Item(s) in Cart ({cart.length})
                </TopText>
                <TopText onClick={() => navigate("/wishlist")}>
                  Wishlist
                </TopText>
              </>
            </MobileTopTexts>

            <Top>
              <TopButton onClick={() => navigate("/productList")}>
                CONTINUE SHOPPING
              </TopButton>
              <TopTexts>
                <TopText>
                  Item(s) in Cart ({cart.length})
                </TopText>
                <TopText onClick={() => navigate("/wishlist")}>
                  Wishlist
                </TopText>
              </TopTexts>
              <TopButton type="filled">CHECKOUT NOW</TopButton>
            </Top>

            <Bottom>
              <Info>
                {reverseCart.map((item) => (
                  <Product key={item.id}>
                    <ProductDetail>
                      <Image src={item.img} />
                      <Details>
                        <ProductName>
                          <Bold>PRODUCT:</Bold> {item.title}
                        </ProductName>
                        <ProductId>
                          <Bold>PRODUCT ID:</Bold> {item.id}
                        </ProductId>
                        <ProductColor color={item.color[0]} />
                        <ProductSize>
                          <Bold>SIZE:</Bold> {item.size[0]}
                        </ProductSize>
                        <RemoveItem
                          onClick={() => handleClick("remove", { item })}
                        >
                          Remove
                        </RemoveItem>
                      </Details>
                    </ProductDetail>
                    <PriceDetail>
                      <ProductQuantityContainer>
                        <Add
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick("add", { item })}
                        />
                        <Quantity>{item.quantity}</Quantity>
                        <Remove
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick("minus", { item })}
                        />
                      </ProductQuantityContainer>
                      <ProductPrice>
                        &#8358;{formatter.format(item.price * item.quantity)}
                      </ProductPrice>
                    </PriceDetail>
                  </Product>
                ))}
              </Info>
              <Summary>
                <SummaryTitle>ORDER SUMMARY</SummaryTitle>
                <SummaryItem>
                  <SummaryItemText>Subtotal: </SummaryItemText>
                  <SummaryItemPrice>
                    &#8358;{formatter.format(subtotal)}
                  </SummaryItemPrice>
                </SummaryItem>

                <SummaryItem>
                  <SummaryItemText>Estimated Shipping: </SummaryItemText>
                  <SummaryItemPrice>
                    &#8358;{formatter.format(shipping)}
                  </SummaryItemPrice>
                </SummaryItem>

                <SummaryItem>
                  <SummaryItemText>Shipping Discount: </SummaryItemText>
                  <SummaryItemPrice>
                    <u>-&#8358;{formatter.format(discount)}</u>
                  </SummaryItemPrice>
                </SummaryItem>

                <SummaryItem type="total">
                  <SummaryItemText>Total: </SummaryItemText>
                  <SummaryItemPrice>
                    &#8358;{formatter.format(total)}
                  </SummaryItemPrice>
                </SummaryItem>

                <Button>CHECKOUT NOW</Button>
              </Summary>
            </Bottom>
          </>
        )}
      </Wrapper>
      <Newsletter />
    </Container>
  );
};

export default Cart;
