import React from "react";
import styled from "styled-components";
import { Badge } from '@mui/material';
import { styled as styledBadge } from "@mui/system";
import { FaSearch, FaShoppingCart } from "react-icons/fa";
import { useRef } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useSelector } from "react-redux";

import { mobile, tab } from "../responsive";

const Container = styled.div`
  height: 60px;
  max-width: 100%;
  margin, padding: 0;
  ${mobile({ height: "50px" })};
`;

const Wrapper = styled.div`
  max-width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mobile({ padding: "10px 0" })};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  ${mobile({ flex: "0.5" })};
  ${tab({ marginRight: "10px" })};
`;

const Language = styled.span`
  font-size: 14px;
  cursor: pointer;
  ${mobile({ display: "none" })};
`;

const SearchContainer = styled.div`
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  margin-left: 25px;
  padding: 5px;
  cursor: pointer;
`;

const Input = styled.input`
  border: none;
  cursor: pointer;
  ${mobile({ width: "50px" })};
`;

const Center = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${tab({ justifyContent: "center" })};
`;

const LogoImg = styled.img`
  width: 45px;
  height: 45px;
  ${mobile({ width: "35px", height: "auto" })};
`;

const Sitename = styled.span`
  font-size: 45px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 10px;
  color: #c67729;
  ${mobile({ marginLeft: "3px", fontSize: "25px" })};
`;

const Right = styled.div`
  flex: 1.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${mobile({ flex: 2, justifyContent: "center" })};
`;

const MenuItem = styled.div`
  font-size: 14px;
  text-align: center;
  margin-left: 20px;
  cursor: pointer;
  ${mobile({ fontSize: "20px", marginLeft: "10px" })};
  ${tab({ fontSize: "30px" })};
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ${tab({ justifyContent: "center" })};
`;

const Button = styled.button``;

const Navbar = () => {
  const navRef = useRef();

  let cartItems;
  let cartCount = useSelector((state) => state.cartCounter.value);

  const StyledBadge = styledBadge(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      fontSize: "8px",
    }
  }));

  const showNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  const handleClick = (event) => {
    if (!event.currentTarget.classList.contains("responsive_nav")) {
      showNavBar();
    }
  };

  return (
    <Container>
      <Wrapper>
        <Left>
          <Language>EN</Language>
          <NavLink className="link" to="/search">
            <SearchContainer>
              <Input placeholder="Search" disabled />
              <FaSearch style={{ color: "gray", fontSize: 16 }} />
            </SearchContainer>
          </NavLink>
        </Left>
        <Center>
          <NavLink className="link" to="/">
            <LogoImg src="../logo.png" alt="logo" />
          </NavLink>
          <NavLink className="link" to="/">
            <Sitename>ULTRAMALL</Sitename>
          </NavLink>
        </Center>
        <Right>
          <Nav ref={navRef} className="nav">
            <NavLink className="link" to="/">
              <MenuItem ref={navRef} onClick={handleClick}>
                HOME
              </MenuItem>
            </NavLink>
            <NavLink className="link" to="/productList">
              <MenuItem ref={navRef} onClick={handleClick}>
                PRODUCTS
              </MenuItem>
            </NavLink>
            <NavLink className="link" to="/category">
              <MenuItem ref={navRef} onClick={handleClick}>
                CATEGORIES
              </MenuItem>
            </NavLink>
            <NavLink className="link" to="/wishlist">
              <MenuItem ref={navRef} onClick={handleClick}>
                WISHLIST
              </MenuItem>
            </NavLink>
            <NavLink className="link" to="/register">
              <MenuItem ref={navRef} onClick={handleClick}>
                REGISTER
              </MenuItem>
            </NavLink>
            <NavLink className="link" to="/login">
              <MenuItem ref={navRef} onClick={handleClick}>
                SIGNIN
              </MenuItem>
            </NavLink>
            <Button className="nav-btn nav-close-btn" onClick={showNavBar}>
              <FaTimes />
            </Button>
          </Nav>
          <NavLink className="link" to="/cart">
            <MenuItem>
              <Badge badgeContent={cartCount} color="error" size="small">
                <FaShoppingCart style={{ color: "grey", fontSize: "18px" }} />
              </Badge>
            </MenuItem>
          </NavLink>
          <MenuItem>
            <Button className="nav-btn" onClick={showNavBar}>
              <FaBars style={{ color: "grey", fontSize: "18px" }} />
            </Button>
          </MenuItem>
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
