import React from "react";
import { Routes, Route } from "react-router-dom";
import CryptoJS from "crypto-js";
import "./App.css";

// import pages below
import Home from "./pages/Home";
import Category from "./pages/Category";
import ProductList from "./pages/ProductList";
import Product from "./pages/Product";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Cart from "./pages/Cart";
import SearchPage from "./pages/Search";
import Navbar from "./components/Navbar";
import Announcement from "./components/Announcement";
import Footer from "./components/Footer";
import Wishlist from "./pages/Wishlist";
import CategoryProducts from "./pages/CategoryProducts";
import CheckConnection from "./components/CheckConnection";
import { useDispatch } from "react-redux";
import { set } from "./features/cartCounter/cartCounterSlice";
import { setWishlist } from "./features/wishlist/wishlistSlice";

function App() {
  const dispatch = useDispatch();

  if (localStorage.getItem("ultramall-cart")) {
    let tempCart = localStorage.getItem("ultramall-cart");
    let Decrypted = CryptoJS.AES.decrypt(tempCart, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
    Decrypted = JSON.parse(Decrypted);
    let count = Decrypted.length;
    dispatch(set(count));
  }

  if (localStorage.getItem("ultramall-wishList")) {
    let Wishlist = localStorage.getItem("ultramall-wishList");
    let Decryted = CryptoJS.AES.decrypt(Wishlist, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
    dispatch(setWishlist(JSON.parse(Decryted)));
  }

  return (
    <>
      {/* <CheckConnection> */}

      <Navbar />
      <Announcement />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/category" element={<Category />} />
        <Route path="/categoryProd/:id" element={<CategoryProducts />} />
        <Route path="/productlist" element={<ProductList />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route
          path="*"
          element={
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "60vh",
                alignItems: "center",
                justifyContent: "center",
                borderBottom: "1px solid #eee",
                textTransform: "uppercase",
              }}
            >
              <h1>404 - Page Not Found!!!</h1>
            </div>
          }
        />
      </Routes>
      <Footer />

      {/* </CheckConnection> */}
    </>
  );
}

export default App;
