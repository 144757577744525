import React from "react";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import { popularProducts } from "../data";
import Product from "./Product";

const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Products = () => {
  const wishlist = useSelector((state) => state.wishlist.list);

  // Format Product wishlist
  const formatWishlist = () => {
    return popularProducts.map((item) => {
      if (wishlist.find((wish) => wish.id === item.id)) {
        return { ...item, iswishList: true };
      }
      return item;
    })
  }

  let Products = formatWishlist();

  return (
    <Container>
      {Products.map((item) => {
        return <Product item={item} key={item.id} />;
      })}
    </Container>
  );

};

export default Products;
