import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Add, Remove, Favorite, FavoriteBorderOutlined, } from "@mui/icons-material";
import CryptoJS from "crypto-js";
import Swal from "sweetalert2";
import styled from "styled-components";

import { set } from "../features/cartCounter/cartCounterSlice";
import { mobile, tab } from "../responsive";
import { popularProducts } from "../data";
import { useSelector, useDispatch } from "react-redux";
import { addToWishlist, reduceWishlist } from "../features/wishlist/wishlistSlice";
import Newsletter from "../components/Newsletter";


const Container = styled.div``;

const Wrapper = styled.div`
  padding: 50px;
  display: flex;
  ${mobile({ padding: "10px", flexDirection: "column" })};
  flex-wrap: wrap;
`;

const ImgContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

const Img = styled.img`
  width: 300px;
  height: auto;
  ${mobile({ height: "40vh" })}
`;

const InfoContainer = styled.div`
  flex: 1;
  padding: 0 50px;
  ${mobile({ padding: "0 10px" })};
`;

const Title = styled.h1`
  font-weight: 200;
`;

const Desc = styled.p`
  margin: 20px 0;
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Price = styled.p`
  font-weight: 100;
  font-size: 40px;
  margin-bottom: 30px;
`;

const WishlistDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 10px;
  cursor: pointer;
`;

const FilterContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Filter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterTitle = styled.span`
  font-size: 20px;
  font-weight: 200;
`;

const FilterColor = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin: 0 5px;
  cursor: pointer;
`;

const FilterSize = styled.select`
  margin-left: 10px;
  padding: 5px;
  cursor: pointer;
`;

const FilterSizeOption = styled.option`
  padding: 5px;
`;

const AddContainer = styled.div`
  display: flex;
  align-items: center;
  ${mobile({ width: "100%" })};
`;

const QuantityContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
`;

const Quantity = styled.span`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid teal;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const Button = styled.button`
  padding: 15px;
  border: 2px solid teal;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  margin-left: 30px;

  &:hover {
    background-color: teal;
    border: none;
    color: white;
    transition: all 0.4s ease;
  }

  &:active {
    background-color: #f8f4f4;
    color: #000;
  }
`;

const Product = () => {
  const formatter = new Intl.NumberFormat("en");
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let prod = popularProducts.find((item) => {
    if (item.id == id) return item;
  });

  const [product, setProduct] = useState(prod);

  const wishlist = useSelector((state) => state.wishlist.list);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (wishlist.find(wish => wish.id === prod.id)) {
      prod = { ...prod, iswishList: true };
    }
    setProduct(prod);
  }, [wishlist]);

  // Edit Product Quantity
  const editQuantity = (option) => {
    var newQuantity = 0;

    if (option === "add") {
      newQuantity = Number(product.quantity) + 1;
    } else {
      if (product.quantity > 1) {
        newQuantity = Number(product.quantity) - 1;
      }
    }
    let temp = { ...product, quantity: newQuantity }

    setProduct(temp);
  }

  // Select Product Color
  const selectColor = ({ option }) => {
    let colors = product.color;
    let index = colors.indexOf(option);
    if (!index === 0) {
      let otherColors = colors.filter((color) => color !== option);
      let shuffledColors = [option, ...otherColors];
      let temp = { ...product, color: shuffledColors };
      setProduct(temp);
    }
  }

  // Select Product Size
  const selectSize = (option) => {
    let sizes = product.size;
    let index = sizes.indexOf(option);
    if (!index === 0) {
      let otherSizes = sizes.filter((size) => size !== option);
      sizes = [option, ...otherSizes];
      let temp = { ...product, size: sizes };
      setProduct(temp);
    }
  }

  // Add or Remove from Wishlist
  const toggleWishlist = () => {
    if (product.iswishList === false) {
      dispatch(addToWishlist(product));
    } else {
      dispatch(reduceWishlist(product.id));
    };
  };

  const SetCart = (data) => {
    let tempCart = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET).toString();
    localStorage.setItem("ultramall-cart", tempCart);
    dispatch(set(data.length));
  }

  const GetCart = () => {
    let tempCart = localStorage.getItem("ultramall-cart");
    if (tempCart) {
      let Decrypted = CryptoJS.AES.decrypt(tempCart, process.env.REACT_APP_SECRET).toString(CryptoJS.enc.Utf8);
      return JSON.parse(Decrypted);
    }
    return [];
  }

  const addToCart = () => {
    console.log("Beginning beginning:");
    if (!localStorage.getItem("ultramall-cart")) {
      let cart = [];
      cart.push(product);
      console.log("Beginning:", cart);
      SetCart(cart);
      console.log(cart);
    } else {
      let storedCart = GetCart();
      console.log(storedCart);
      let inCart = false;
      for (var i = 0; i < storedCart.length; i++) {
        if (storedCart[i].id === product.id) {
          inCart = true;
          break;
        }
      }
      if (inCart === false) {
        storedCart.push(product);
        localStorage.removeItem("ultramall-cart");
        SetCart(storedCart);
      }
    }

    Swal.fire({
      title: "Added to Cart!!!",
      text: "Your product has been added to Cart",
      icon: "success",
      confirmButtonColor: "teal",
      confirmButtonText: "Go to Cart",
      showDenyButton: true,
      denyButtonColor: "black",
      denyButtonText: "Continue Shopping",
    }).then(result => {
      if (result.isConfirmed) {
        navigate("/cart")
      } else {
        navigate("/productList")
      }
    });
  };

  return (
    <Container>
      <Wrapper>
        <ImgContainer>
          <Img src={product.img} />
        </ImgContainer>
        <InfoContainer>
          <Title>{product.title}</Title>
          <Desc>{product.desc}</Desc>
          <PriceContainer>
            <Price>&#8358;{formatter.format(product.price * product.quantity)}</Price>
            <WishlistDiv onClick={() => toggleWishlist()}>
              {!product.iswishList ? (
                <FavoriteBorderOutlined />
              ) : (
                <Favorite style={{ color: "red" }} />
              )}
            </WishlistDiv>
          </PriceContainer>
          <FilterContainer>
            <Filter>
              <FilterTitle>Color</FilterTitle>
              {product.color.map((color) => {
                if (
                  color === "white" ||
                  color === "whitesmoke" ||
                  color === "#fff" ||
                  color === "#ffffff" ||
                  color === "#f5f5f5"
                ) {
                  return (
                    <FilterColor
                      color={color}
                      style={{
                        border: "2px solid black",
                        width: "17.5px",
                        height: "17.5px",
                      }}
                      key={color}
                      onClick={() => selectColor({ color })}
                    />
                  );
                }
                return <FilterColor color={color} />;
              })}
            </Filter>
            <Filter>
              <FilterTitle>Size</FilterTitle>
              <FilterSize>
                {product.size.map((size) => {
                  return <FilterSizeOption key={size}>{size}</FilterSizeOption>;
                })}
              </FilterSize>
            </Filter>
          </FilterContainer>
          <AddContainer>
            <QuantityContainer>
              <Add style={{ cursor: "pointer" }} onClick={() => editQuantity("add")} />
              <Quantity>{product.quantity}</Quantity>
              <Remove style={{ cursor: "pointer" }} onClick={() => editQuantity("remove")} />
            </QuantityContainer>
            <Button onClick={() => addToCart()}>ADD TO CART</Button>
          </AddContainer>
        </InfoContainer>
      </Wrapper>

      <Newsletter />
    </Container >
  );
};

export default Product;
