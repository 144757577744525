import React from "react";
import {
  Facebook,
  Instagram,
  Twitter,
  Room,
  Phone,
  Mail,
  FlashOn,
} from "@mui/icons-material";
import styled from "styled-components";
import { mobile } from "../responsive";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  ${mobile({
  flexDirection: "column",
  padding: "10px",
})};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;
const Logo = styled.h1``;

const Desc = styled.p`
  margin: 20px 0;
`;
const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;
const SocialIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  background-color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Copyright = styled.p``;

const Center = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ borderTop: "1px solid #eee" })};
`;

const Title = styled.h3`
  margin-bottom: 30px;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;

const ListItem = styled.li`
  width: 50%;
  margin-bottom: 10px;
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
  ${mobile({ borderTop: "1px solid #eee" })};
`;

const ContactItem = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;

const Powered = styled.a`
  text-decoration: none;
  color: #e4405f;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: none;
    color: #e4405f;
  }
`;

const Hr = styled.hr`
  background-color: #eee;
  border: none;
  height: 2px;
`;

const PaymentDiv = styled.div`
  margin: 30px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Payment = styled.img`
  width: 980px;
  max-width: 100%;
  height: auto;
`;

const Footer = () => {
  return (
    <>
      <Container>
        <Left>
          <Logo>Ultramall</Logo>
          <Desc>
            This is a Super High performance mall, where quality is at the helm
            of our operations. We certainly serve you best.
          </Desc>
          <SocialContainer>
            <SocialIcon color="#3B5999">
              <Facebook />
            </SocialIcon>
            <SocialIcon color="#E4405F">
              <Instagram />
            </SocialIcon>
            <SocialIcon color="#55ACEE">
              <Twitter />
            </SocialIcon>
          </SocialContainer>
          <Copyright>&copy; Ultramall {new Date().getFullYear()}</Copyright>
        </Left>
        <Center>
          <Title>Useful Links</Title>
          <List>
            <ListItem>Home</ListItem>
            <ListItem>Cart</ListItem>
            <ListItem>Categories</ListItem>
            <ListItem>Men Fashion</ListItem>
            <ListItem>Women Fashion</ListItem>
            <ListItem>Accessories</ListItem>
            <ListItem>My Account</ListItem>
            <ListItem>Order Tracking</ListItem>
            <ListItem>Wishlist</ListItem>
            <ListItem>Terms & Conditions</ListItem>
          </List>
        </Center>
        <Right>
          <Title>Contact</Title>
          <ContactItem>
            <Room /> BOA Plaza, Central Business District, Abuja, Nigeria
          </ContactItem>
          <ContactItem>
            <Phone /> +2348036940709
          </ContactItem>
          <ContactItem>
            <Mail /> info.ultramall@gmail.com
          </ContactItem>
          <Powered href="https://brainytech.com.ng">
            <FlashOn />
            Powered by Brainytech Networks
          </Powered>
        </Right>
      </Container>
      <Hr />
      <PaymentDiv>
        <Payment src="./images/payment/paystack.jpg" />
      </PaymentDiv>
    </>
  );
};

export default Footer;
