import { configureStore } from "@reduxjs/toolkit";
import cartCounterReducer from "./features/cartCounter/cartCounterSlice";
import wishlistReducer from "./features/wishlist/wishlistSlice";

export const store = configureStore({
    reducer: {
        cartCounter: cartCounterReducer,
        wishlist: wishlistReducer,
    }
});
