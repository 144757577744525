import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Newsletter from "../components/Newsletter";
import Product from "../components/Product";
import { mobile } from "../responsive";
import { useSelector } from "react-redux";

const Container = styled.div``;

const Title = styled.h1`
  margin: 20px;
`;

const Products = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;

  ${mobile({ padding: "10px", flexDirection: "column" })}
`;

const Empty = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;  
  padding-bottom: 10px;
  justify-content: center;
  align-items: center;
`;

const HeartBreak = styled.p`
  font-size: 50px;
  margin-bottom: 20px; 
`;

const Button = styled.button`
  padding: 10px;
  margin-top: 20px;
  background-color: teal;
  color: white;
  border: none;
  cursor: pointer;
`;

const Wishlist = () => {
  const navigate = useNavigate();
  const tempProducts = useSelector((state) => state.wishlist.list);
  let Wishlist = tempProducts.map((item) => {
    return (
      { ...item, iswishList: true }
    );
  });

  Wishlist.reverse();

  return (
    <Container>
      <Title>Wishlist</Title>
      <Products>
        {
          Wishlist.length === 0 && (
            <Empty>
              <HeartBreak><span role="img" aria-label="HeartBreak">&#128148;</span></HeartBreak>
              <h3>Your Wishlist is Empty!!!</h3>
              <Button onClick={() => navigate("/ProductList")}>Continue Shopping</Button>
            </Empty>
          )
        }

        {Wishlist.map((item) => {
          return <Product item={item} key={item.id} />;
        })}
      </Products>
      <Newsletter />
    </Container>
  );
};

export default Wishlist;
