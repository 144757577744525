import React from "react";
import SkeletonElement from "./SkeletonElement";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 10px;
`;
const Container = styled.div``;

const SkeletonProduct = () => {
  return (
    <Wrapper>
      <Container>
        <SkeletonElement type="thumbnail" />
        <SkeletonElement type="title" />
        <SkeletonElement type="text" />
        <SkeletonElement type="body" />
      </Container>
    </Wrapper>
  );
};

export default SkeletonProduct;
